import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import * as feather from 'feather-icons';
import { ThemeService } from '../../services/theme.service';
import { TabService } from '../../tab.service';
import { Router } from '@angular/router';

@Component({
  // standalone: true,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit{
  userDetails:any;
  themeValue = false
  sidebarType:any;
  tabs$ = this.tabService.tabs$;
  activeTab$ = this.tabService.activeTab$;
  showActiveClass = true
  showDropdown=false
  accounts: any;
  constructor(private auth : AuthService,public themeService: ThemeService, public tabService: TabService,public router: Router){
    const getUserDetails:any = this.auth.getUserDetails() ? this.auth.getUserDetails() : localStorage.getItem('user_details');
    this.userDetails = JSON.parse(getUserDetails)
    // console.log("=====xuserDetails+++++++++++",this.userDetails)
  this.accounts = JSON.parse(localStorage.getItem('CurrentAccount')!)

  }

  ngOnInit(): void {
    const theme = localStorage.getItem('darkTheme')
    this.sidebarType = localStorage.getItem('sidebarType')!
    this.themeValue = theme === 'true' ? true : false
    const isExpanded = localStorage.getItem('expandedSidebar')
    if(isExpanded === 'true'){
      this.showActiveClass = true
    }
    else{
      this.showActiveClass = false
    }
  }
  toggleNav(){
    const toggleBtn:any = document.getElementById('toggle_btn');

    const body = document.body;
    if (body.classList.contains('mini-sidebar')) {
      body.classList.remove('mini-sidebar');
      // toggleBtn.classList.add('active');
      // localStorage.setItem(screenModeNightTokenState, 'night');
      setTimeout(() => {
        body.classList.remove('mini-sidebar');
        document.querySelector(".header-left")?.classList.add("active");
      }, 100);
    }else {
      body.classList.add('mini-sidebar');
      toggleBtn.classList.remove('active');
      // localStorage.removeItem(screenModeNightTokenState);
      setTimeout(() => {
        body.classList.add('mini-sidebar');
        document.querySelector(".header-left")?.classList.remove("active");
      }, 100);
  } 
  this.toggleSidebar()
} 

toggleNavMobile(){
  const toggleBtn:any = document.getElementById('mobile_btn');

  const body = document.body;
  // const wrapper = document.querySelector(".main-wrapper");
  
  if (document.querySelector(".slide-nav")) {
    document.querySelector(".main-wrapper")?.classList.remove("slide-nav");
    
    body.classList.remove('menu-opened');
    // toggleBtn.classList.add('active');
    // localStorage.setItem(screenModeNightTokenState, 'night');
    setTimeout(() => {
      body.classList.remove('mini-sidebar');
      document.querySelector(".header-left")?.classList.add("active");
    }, 100);
  }else {
    document.querySelector(".main-wrapper")?.classList.add("slide-nav");

    body.classList.add('menu-opened');
    toggleBtn.classList.remove('active');
    // localStorage.removeItem(screenModeNightTokenState);
    setTimeout(() => {
      body.classList.add('mini-sidebar');
      document.querySelector(".header-left")?.classList.remove("active");
    }, 100);
} 
} 

ngAfterViewInit() {
  feather.replace();
}

logOut(){
  this.auth.logOut()
}
onClickedOutside(e: Event) {
  const toggleBtn:any = document.getElementById('mobile_btn');
  const body = document.body;
  this.showDropdown = false
  if (document.querySelector(".slide-nav")) {
    document.querySelector(".main-wrapper")?.classList.remove("slide-nav");
    
    body.classList.remove('menu-opened');
    // toggleBtn.classList.add('active');
    // localStorage.setItem(screenModeNightTokenState, 'night');
    setTimeout(() => {
      body.classList.remove('mini-sidebar');
      document.querySelector(".header-left")?.classList.add("active");
    }, 100);
  }
}
toggleTheme() {
  this.themeService.toggleTheme();
  const theme = localStorage.getItem('darkTheme')
    this.themeValue = theme === 'true' ? true : false
    const bgType = localStorage.getItem('bgType');
    const primaryColor = localStorage.getItem('backgroundPrimaryColor')
    document.documentElement.style.setProperty('--background-primary-color', primaryColor);
    document.documentElement.style.setProperty('--background-primary-hover-color', (primaryColor + '14'));
    const mainBgColor = localStorage.getItem('backgroundMainColor')
    if(bgType === 'gradiant'){
      document.body.style.backgroundColor = ''
      document.body.style.setProperty('--main-background-color', mainBgColor);
    }
    else{
      document.body.style.setProperty('--main-background-color', mainBgColor);
      if(this.themeValue){
        document.body.style.backgroundColor = ''
      }
      else{
        document.body.style.backgroundColor = mainBgColor!
      }
    }
}

selectTab(index: number) {
  this.tabService.setActiveTab(index);
  const tab = this.tabService.tabs[index];
  this.router.navigate([tab.path], { queryParamsHandling: 'preserve' });
}

closeTab(index: number, event: MouseEvent) {
  event.stopPropagation();
  this.tabService.closeTab(index);
  const tabs = this.tabService.tabs;
  if (tabs.length > 0) {
    const activeTab = this.tabService.activeTab;
    this.router.navigate([tabs[activeTab].path], { queryParamsHandling: 'preserve' });
  } else {
    // this.router.navigate(['/home']);
  }
}

closeAllTab(event:MouseEvent){
  event.stopPropagation();
  this.tabService.closeAllTab()
}

toggleSidebar(){
  const isExpanded = localStorage.getItem('expandedSidebar')
  if(isExpanded === 'true'){
    localStorage.setItem('expandedSidebar', 'false')
  }
  else{
    localStorage.setItem('expandedSidebar', 'true')
  }
}

refreshRouter() {
  const currentUrl = this.router.url;
  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    this.router.navigate([currentUrl]);
  });
}

}
